( function() {

    document.addEventListener("DOMContentLoaded", () => {
        const sliderController = document.getElementById('testimonials-slider-controller'),
              sliderItemsEl = document.getElementById('testimonials-slider-items'),
              sliderSpeed = 500;

        var testimonialsControlSlider = new Swiper(sliderController, {
            slidesPerView: 5,
            initialSlide: 0,
            on: {
                beforeResize: function () {
                    testimonialsItemsSlider.slideTo(0, sliderSpeed);
                },
            }
        });

        var testimonialsItemsSlider = new Swiper(sliderItemsEl, {
            fadeEffect: { crossFade: true },
            effect: "fade",
            slidesPerView: 1,
            speed:sliderSpeed,
            initialSlide: 0,
            spaceBetween: 120,
            allowTouchMove: false,
            autoHeight: true,
            breakpoints: {
                1920: {
                    spaceBetween: 180,
                }
            }
        });

        const resetControlItems = () => {
            controlItems.forEach(function (item, index) {
                item.classList.remove('swiper-slide-active');
            });
        }

        var controlItems = document.querySelectorAll('#testimonials-slider-controller .swiper-slide');
        
            
        controlItems.forEach(function (item, index) {
            item.addEventListener('click', function (event) {
                event.preventDefault();
                resetControlItems();
                testimonialsItemsSlider.slideTo(index, sliderSpeed);
                item.classList.add('swiper-slide-active');
            });
        });

        // slide change handlers
        testimonialsControlSlider.on('slideChange', function () {
            testimonialsItemsSlider.slideTo(this.activeIndex, sliderSpeed);
        });

    });
}() );